<template>
  <div class="form-user">
    <!-- ユーザーアイコン画像 -->
    <label class="form-user__upload">
      <img class="form-user__upload__img" v-if="icon" :src="icon" />
      <div class="form-user__upload__cover">
        <v-icon class="form-user__upload__cover__icon">camera_alt</v-icon>
        <span class="form-user__upload__cover__icon__required">*</span>
      </div>
      <input class="form-user__upload__input"
             @change="selectPicture" type="file" accept=".png, .jpg, .jpeg, .gif" />
    </label>
    <!-- ユーザー名 -->
    <label class="form-user__label">名前<span class="form-user__label__required">*</span></label>
    <input class="form-user__name" placeholder="山田太郎" v-model="name" type="text" @input="emitData('name', name)" />
    <div class="form-user__count">
      <span :class="{ 'form-user__count__over' : nameLength > NAME_MAX_LENGTH }">{{ nameLength }}/{{ NAME_MAX_LENGTH }}</span>
    </div>
    <!-- 自己紹介 -->
    <label class="form-user__label">自己紹介文<span class="form-user__label__required">*</span></label>
    <textarea class="form-user__textarea" placeholder="仕事や趣味など自由に記載しましょう。" v-model="profile" type="text" @input="emitData('profile', profile)" />
    <div class="form-user__count">
      <span :class="{ 'form-user__count__over' : profileLength > PROFILE_MAX_LENGTH }">{{ profileLength }}/{{ PROFILE_MAX_LENGTH }}</span>
    </div>
  </div>
</template>

<script>
import image from '@/assets/lib/image'

export default {
  mixins: [image],
  props: {
    // ユーザー情報
    user: {
      type: Object
    }
  },
  data () {
    return {
      // プロフィール画像
      icon: '',
      // 名前
      name: '',
      // 自己紹介文
      profile: ''
    }
  },
  mounted () {
    // ユーザー情報の格納
    Object.keys(this.$data).forEach(key => {
      if (this.user && this.user[key]) this.$data[key] = this.user[key]
    })
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Number} 名前の文字数上限（固定）
     */
    NAME_MAX_LENGTH () {
      return 20
    },
    /**
     * @return {Number} 自己紹介文の文字数上限（固定）
     */
    PROFILE_MAX_LENGTH () {
      return 160
    },
    /**
     * @return {Number} 名前の文字数カウント
     */
    nameLength () {
      return this.name.length
    },
    /**
     * @return {Number} 自己紹介文の文字数カウント
     */
    profileLength () {
      return this.profile.length
    }
  },
  methods: {
    /**
     * 画像の選択
     * @param {Object} event 発火したイベント
     */
    async selectPicture (event) {
      event.preventDefault()

      // 保存先URLパスとファイルの格納
      const file = event.target.files[0]

      const path = '/users/' + this.uid + '/icon/'
      const results = await this.doImgProcess(file, path)
      if (!results) return

      this.icon = results.url
      this.emitData('icon', results.url)
      this.$emit('set-blob', results.blob, results.path)
    },
    /**
     * 親コンポーネントへデータを渡す
     * @param {String} key
     * @param {String} value
     */
    emitData (key, value) {
      this.$emit('set-postparams', key, value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.form-user {
  &__upload {
    position: relative;
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    background-color: $gray_lighten_color;
    border-color: transparent;
    border-radius: 50%;
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    &__cover {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      align-items: flex-start;
      transform: translate(-50%, -50%);
      &__icon {
        font-family: $material-outlined;
        object-fit: cover;
        &.v-icon {
          font-size: 4.5rem;
          color: $gray_color;
        }
        &__required {
          font-size: 1.6rem;
          line-height: 2rem;
          color: $red_color;
        }
      }
    }
    &__input {
      display: none;
    }
  }
  &__label {
    display: block;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    &__required {
      color: $red_color;
    }
  }
  &__name {
    width: 100%;
    max-width: $max_width;
    height: 30px;
    padding: 5px 0;
    font-size: 1.6rem;
    line-height: 2rem;
    border-bottom: 1px solid $black_color;
    outline: 0;
    &::placeholder {
      color: $gray_color;
    }
  }
  &__count {
    margin-top: 5px;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: right;
    &__over {
      color: $red_color;
    }
  }
  &__textarea {
    display: block;
    width: 100%;
    height: 110px;
    padding: 5px 0;
    overflow: hidden;
    font-size: 1.6rem;
    line-height: 2rem;
    resize: none;
    border-bottom: 1px solid $black_color;
    outline: none;
    &::placeholder {
      color: $gray_color;
    }
  }
}
</style>
